// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #00B869;  //009b72
$primary-2:     #046865;
$primary-3:     #192d35;

// $primary: #163460;
// $primary-2: #0074ff;
// $primary-3: #00A9FE;

$white: #fdfdff;
$black: #163460;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);