// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.


.green {
    color: $primary;
}

.yellow {
    color: $yellow;
}

.red {
    color: $red;
}

.white {
    color: $white;
}

.card-footer-tighter {
    padding: 0.75rem 1.25rem;
}

.nav-item-inline {
    inline-size: max-content !important;
}

// .navbar {
//     z-index: 1;
// }


// .modal-backdrop {
//   z-index: 0;
// }

// @font-face {
//     font-family: 'Cera Pro';
//     src: url('cerapro-regular-webfont.woff2') format('woff2'),
//          url('cerapro-regular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
